<template>
	<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="flex title_box">
			<Title title="钱包转换" />
			<button class="save" @click="sub">确认转换</button>
		</div>
		<div class="ct_box">
			<p class="ct_title">此界面是收益钱包转换普通钱包(普通钱包用来发稿)</p>
			<p class="ct_title2">
				<!-- 钱包转化享受2%的补贴(整数取整，100元起)，发现恶意刷单操作，冻结账号余款不退。 -->
			</p>
			<p class="ct_title3">
				账户余额：<span>￥{{ user_info.money }}</span>元
			</p>
			<p class="ct_title3">
				收益钱包：<span>￥{{ user_info.wallet }}</span>元
			</p>
			<div class="flex" style="margin-bottom: 20px; align-items: center">
				<p class="ct_title4">转化金额：</p>
				<el-input v-model="amount" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
					oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
					placeholder="转化金额"></el-input>
				<p style="margin-left: 0.5rem; font-size: 1.33rem">元</p>
			</div>
			<p class="remark">
				收益钱包余款<span>{{ user_info.wallet }}</span>元，已转化<span>{{ income.total_zh }}</span>元，累计收益<span>{{
					income.total_sr }}</span>元，提现中<span>{{ income.total_tx }}</span>元
			</p>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	computed: {},
	mounted() {
		this.getIncome()
	},
	data() {
		return {
			amount: '',
			fullscreenLoading: false,
			income: [],
			user_info: this.$util.getLocalStorage('user_info'),
		}
	},
	methods: {
		// 获取用户信息
		getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    console.log(this.$user_info,'user_info');
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
		// 收益
		getIncome() {
			this.curlGet('/api/users/money_log/income').then((res) => {
				if (res.data.code) {
					this.income = res.data.data
				}
			})
		},
		sub() {
			this.fullscreenLoading = true
			this.curlPost('/api/users/tocash/conversion', {
				amount: this.amount,
			}).then((res) => {
				if (res.data.code) {
					this.$message({
						message: '转换成功',
						type: 'success',
					})
					this.getUserInfo()

					setTimeout(() => {
						this.fullscreenLoading = false
						return this.$router.go(0)
					}, 500)
				} else {
					this.fullscreenLoading = false
					return this.$message({
						message: res.data.msg,
						type: 'warning',
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/WalletConversion';
</style>